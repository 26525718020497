import React from 'react'
import styled from 'styled-components'
import { Avatar } from '../../atoms/Avatar'
import { Link } from 'gatsby'
import { Reviews } from '../../molecules/Reviews'
import { Text, size } from '../../../youtalk-storybook/src/ui'

const Row = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  height: 90px;
  margin-bottom: 8px;

  &,
  &:active,
  &:hover {
    text-decoration: none;
    color: #333;
  }

  @media (max-width: ${size.lg}) {
    gap: 12px;
  }

  @media (max-width: ${size.md}) {
    gap: 16px;
  }

  @media (max-width: ${size.xs375}) {
    height: 110px;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Dot = styled(({ className }) => (
  <svg
    className={className}
    fill="none"
    height="4"
    viewBox="0 0 4 4"
    width="4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" fill="#333333" r="2" />
  </svg>
))`
  margin-left: 4px;
  margin-right: 8px;

  @media (max-width: ${size.xs375}) {
    margin-left: 0;
    margin-right: 4px;
  }
`

const ExperienceWithAge = styled(({ age, experience, className }) => (
  <div className={className}>
    <Text.Average semiBold style={{ whiteSpace: 'nowrap' }}>
      {age} <Dot />
    </Text.Average>
    <Text.Average semiBold style={{ whiteSpace: 'nowrap' }}>
      {`Опыт ${experience}`}
    </Text.Average>
  </div>
))`
  display: flex;
  flex-direction: row;

  @media (max-width: ${size.xs375}) {
    flex-direction: column;
  }
`

const Professions = ({ professions }) => (
  <Text.Average semiBold>
    {professions.map((profession, index) => (
      <React.Fragment key={index}>
        {Boolean(index) && <br />}
        {profession}
      </React.Fragment>
    ))}
  </Text.Average>
)

export const Top = ({
  age,
  experience,
  name,
  professions,
  reviews,
  moreDetailsButtonProps,
  avatarProps
}) => (
  <Row target="_blank" to={avatarProps.href}>
    <Avatar target="_blank" {...avatarProps} />
    <Col>
      <Professions professions={professions} />
      <Text.Large bold>{name}</Text.Large>
      <ExperienceWithAge age={age} experience={experience} />
      {reviews > 0 && (
        <Reviews
          moreDetailsButtonProps={moreDetailsButtonProps}
          reviewsCount={reviews}
        />
      )}
    </Col>
  </Row>
)
